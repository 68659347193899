import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import axios from 'axios';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import 'datatables.net-rowreorder';
import 'datatables.net-responsive';
import SpinnerLoad from '../../../Component/SpinnerLoad/SpinnerLoad';
import './StudentPage.css';
import { Link } from 'react-router-dom';
export class StudentPage extends Component {

  state = {
    studentsData:"",
    loading:true,
    department:{
      1:'EEE',
      2:'CE',
      3:'CSE'
     },
     modalopen:false,
     removeItem:"",
     table:""
  }



  componentDidMount() {


    axios.get('/student/all')
  .then( (response)=> {
    console.log(response.data.data);
    this.setState({studentsData:response.data.data,
  });
  $(document).ready(function() {
    var table = $('#example').DataTable({
      responsive: true,
    });
 
    $('#example tbody').on( 'click', 'tr', function () {
        table.$('tr.selected').removeClass('selected');
        $(this).addClass('selected');
       
    } );
 
    $('#buttonDeleterow').click( function (e) {
      var id = table.row('.selected').id();
      axios.post('/student/delete',{id:id})
      .then( (response)=> {
     console.log(response)
      })
    table.row('.selected').remove().draw( false );
    
  
    } );
} );

//  $(document).ready( () => {
//   var table =      $('#example').DataTable( {
//         //   select: {
//       //     items: 'cell',
//       //     info: false
//       // },
//       //   fixedColumns: true,
//       //   deferRender:    true,
//       //   scroller:       true,
//       //   paging:   false,
//       //   fixedHeader: true,
//       //   searching: true,
     
//         destroy: true,
//         rowId: 'id',
//          rowReorder: {
//             selector: 'td:nth-child(2)'
//         },
//         responsive: true,
//         columnDefs: [
//           { responsivePriority: 1, targets: 0 },
//           { responsivePriority: 2, targets: 1 },
//           { responsivePriority: 3, targets: 2 }
//       ],
//        }  
//   );
//   this.setState({ table:table});
//   });
  
  this.setState({  loading:false})
  })
  .catch(  (error)=> {
    console.log(error);
  })
  }





  deleteDataHandler = (e)=>{
    // document.querySelector("body").style.opacity=.5;
    console.log(e.target.getAttribute("data"));
 this.setState({
      modalopen:true,
      removeItem:e.target.getAttribute("id")
      
    })
    
  }
  deleteData = () =>{
    document.getElementById("buttonDeleterow").click()
    this.setState({
      modalopen:false,
      // removeItem:""
    })
   
  
  }
  closeModal = () =>{
    this.setState({
      modalopen:false,
      removeItem:""
    })
  }
    render() {

      const studentData = this.state.studentsData;
       
   var tableData =   this.state.loading==false? Object.keys(studentData) .map(key=>{
     var dept_id = studentData[key].department_id;
return    <tr id={studentData[key].id} >
  <td><div><button className="viewbutton"><i class="far fa-eye"></i></button>
<Link  to={"/student/edit/"+studentData[key].id} className="editbutton"><i class="far fa-edit"></i></Link>
<button onClick={this.deleteDataHandler} id={studentData[key].id} data={studentData[key].id} className="deletebutton"><i class="fas fa-trash"></i></button> </div></td>
<td>{studentData[key].student_name!=null?studentData[key].student_name:"No Name"}</td>
<td>{studentData[key].department_id!=null?this.state.department[dept_id]:"No Department"}</td>
<td>{studentData[key].phone_no!=null?studentData[key].phone_no:"No Phone"}</td>
<td>{studentData[key].student_email!=null?studentData[key].student_email:"No Email"}</td>
<td>{studentData[key].hometown!=null?studentData[key].hometown:"No HomeTown"}</td>
<td>{studentData[key].batch_no!=null?studentData[key].batch_no:"No Batch"}</td>

 
 
 
 
</tr>
   }):"";

   var tableMake = 
   <table id="example" class="display nowrap" style={{ width: '100%' }} >
   <thead>
       <tr>
       <th scope="col">update</th>
         <th scope="col">Name</th>
          <th scope="col">Department</th>  
         <th scope="col">Phone Number</th>
         <th scope="col">Email</th>
         <th scope="col">Hometown</th>
        <th scope="col">Batch</th>
  
       </tr>
     </thead>
     <tbody>
       {tableData} 
     </tbody>
   </table>;
   
 
        return (
            <div class="container"style={{"padding-top": "10px"}}>
              <button id="buttonDeleterow" hidden>Delete selected row</button>
        <div>
         {this.state.loading?<SpinnerLoad/>:tableMake} 
       </div>
        
 

 
{this.state.modalopen?<div class="modal fade show" id="exampleModalLive"  tabindex="-1"  z-indez="99" aria-labelledby="exampleModalLiveLabel" style={{"display": "block"}} aria-modal="true" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLiveLabel">Modal title</h5>
        <button onClick={this.closeModal} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>Woohoo, you're reading this text in a modal!</p>
      </div>
      <div class="modal-footer">
        <button onClick={this.closeModal} type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button  onClick={this.deleteData} type="button" class="btn btn-danger">Delete</button>
      </div>
    </div>
  </div>
</div>:""}
{this.state.modalopen?<div class="modal-backdrop fade show"></div>:""}

    </div>
        )
    }
}

export default StudentPage
