import React, { Component } from 'react'
import $ from 'jquery'
import axios from 'axios'
export class AddPushNotification extends Component {
    state = {
        ButtonClick:false,
        title:"",
        body:"",
        url:"",

    }
    imageChangeHandler = (e) =>{
        var file = e.target.files[0];
 
        if(file){
            var reader = new FileReader();
 
            reader.onload = function(){
                $("#previewImg").attr("src", reader.result);
            }
 
            reader.readAsDataURL(file);
        }
        

    }


    inputBoxChangeHandler = (e)=>{
     this.setState({
            [e.target.id]:e.target.value
        })
    
      }


    formSubmitHandler = (e)=>{
    
        this.setState({ButtonClick:true,message:"Your Photo Has Uploaded"})
        var formData = new FormData();
       formData.append("image",document.getElementById("image").files[0]);
       formData.append("title",this.state.title);
       formData.append("url",this.state.url);
       formData.append("body",this.state.body);
        axios.post('/push/notification/create', formData )
        .then( (response) => {
          console.log(response );
          this.setState({ButtonClick:false })
         })
        .catch(  (error) => {
          console.log(error);
        });
    
     
       }



    render() {
        return (
            <div className='d-flex justify-content-center'>
                <div  style={{"width":"500px"}}>
                <div class="form-floating mb-3">
  <input type="text" onChange={this.inputBoxChangeHandler}  class="form-control" id="title" placeholder="name@example.com"/>
  <label for="title">Title</label>
</div>
<div class="form-floating">
  <input type="text" onChange={this.inputBoxChangeHandler}  class="form-control" id="url" placeholder="Password"/>
  <label for="url">Url</label>
</div>
<br/>
<div class="form-floating">
  <textarea class="form-control" onChange={this.inputBoxChangeHandler}  placeholder="Leave a comment here" id="body" style={{"height": "100px"}}></textarea>
  <label for="body">Body</label>
</div>


<div class="mb-3">
  <label for="formFile" class="form-label">Image</label>
  <input onChange={this.imageChangeHandler} class="form-control" type="file" id="image"/>
</div>

<img id='previewImg' class="img-fluid" src='https://www.torrentbd.net/images/logo-124.png'  />

<button class="btn btn-primary" onClick={this.formSubmitHandler} type="submit" disabled={this.state.ButtonClick}>
{this.state.ButtonClick?<span><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Updating..</span>:"Update"}
  </button>


</div>     </div>
        )
    }
}

export default AddPushNotification
