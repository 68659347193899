import React from 'react'
import RoutesManage from './RoutesManage';
import { useEffect } from 'react';
 
import {isLogin} from './Component/utils/index';
import axios from 'axios';

 function App() {
   
  return (
    <div>
      <RoutesManage/>
    </div>
  )
}
export default App;