import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <>
                  <footer class="sticky-footer bg-white">
                <div class="container my-auto">
                    <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Your Website 2021</span>
                    </div>
                </div>
            </footer>
            </>
        )
    }
}

export default Footer
