import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class HomePage extends Component {
    render() {
        return (
            <div class="container ">
            {/* <div class="body d-flex  justify-content-center pt-5">
                <div class=" d-flex flex-column">
                 <Link   class="btn btn-info mt-2 mb-2 p-3" to="/department/2"> Civil Engineering (CE)</Link> 
                     <Link  class="btn btn-info mt-2 mb-2 p-3"    to="/department/1"> Electrical and Electronics Engineering (EEE)</Link> 
                     <Link class="btn btn-info mt-2 mb-2 p-3"   to="/department/3"> Computer Science Engineering (CSE)</Link> 
              </div>   
           </div>    */}
         </div>   

        )
    }
}

export default HomePage
