import React, { Component } from 'react'
import { Link } from 'react-router-dom'
export class SideBar extends Component {
    render() {
        return (
            <>
             <ul class={this.props.sidebartoggle?"navbar-nav bg-gradient-primary sidebar sidebar-dark accordion " :"navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"} id="accordionSidebar">

            
<a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
    {/* <div class="sidebar-brand-icon rotate-n-15">
        <i class="fas fa-laugh-wink"></i>
    </div> */}
      <img class="img-fluid" src="/img/headerlogo.png"
                style={{"width": "140px"}}        alt="..."/>
    {/* <div class="sidebar-brand-text mx-3">SB Admin <sup>2</sup></div> */}
</a>


<hr class="sidebar-divider my-0"/>


<li class="nav-item active">
    <Link class="nav-link" to="/">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>Dashboard</span></Link>
</li>


<hr class="sidebar-divider"/>


<div class="sidebar-heading">
    Interface
</div>


<li class="nav-item">
    <Link class="nav-link collapsed" to="/students" data-toggle="collapse" data-target="#collapseTwo"
        aria-expanded="true" aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span>Students</span>
    </Link>
    
</li>


<li class="nav-item">
    <Link class="nav-link collapsed" to="/student/add" data-toggle="collapse" data-target="#collapseUtilities"
        aria-expanded="true" aria-controls="collapseUtilities">
        <i class="fas fa-fw fa-wrench"></i>
        <span>Add Student</span>
    </Link>
</li>


<hr class="sidebar-divider"/>


<div class="sidebar-heading">
    Addons
</div>


<li class="nav-item">
    <Link class="nav-link collapsed" to="/push/notification" data-toggle="collapse" data-target="#collapsePages"
        aria-expanded="true" aria-controls="collapsePages">
        <i class="fas fa-fw fa-folder"></i>
        <span>Push Notification</span>
    </Link>
    <div id="collapsePages" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">Login Screens:</h6>
            <a class="collapse-item" href="login.html">Login</a>
            <a class="collapse-item" href="register.html">Register</a>
            <a class="collapse-item" href="forgot-password.html">Forgot Password</a>
            <div class="collapse-divider"></div>
            <h6 class="collapse-header">Other Pages:</h6>
            <a class="collapse-item" href="404.html">404 Page</a>
            <a class="collapse-item" href="blank.html">Blank Page</a>
        </div>
    </div>
</li>


<li class="nav-item">
    <a class="nav-link" href="charts.html">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>Charts</span></a>
</li>


<li class="nav-item">
    <a class="nav-link" href="tables.html">
        <i class="fas fa-fw fa-table"></i>
        <span>Tables</span></a>
</li>


<hr class="sidebar-divider d-none d-md-block"/>


<div class="text-center d-none d-md-inline">
    <button class="rounded-circle border-0" onClick={this.props.handlemenubar} id="sidebarToggle"></button>
</div>


 

</ul>
           </>
        )
    }
}

export default SideBar
