import axios from 'axios'
import React, { Component } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export class PushNotification extends Component {
  state= {
        data:"",
        loading:true
    }
    componentDidMount(){
        axios.get("/push/notification/all")
         .then(  (response) =>{
          console.log(response.data.data);
          this.setState({data:response.data.data,loading:false})
            
        })
        .catch(  (error)=> {
          console.log(error);
        });
    }

    render() {
var data = this.state.data;
var dataRender  =this.state.loading?"":  Object.keys(data).
map(key=>{
    console.log(key)
   
    return   (<div class="col">
    <div class="card">
      <img src={data[key].image} class="card-img-top img-fluid" alt="..."/>
      <div class="card-body">
      <Link to={"/push/notification/"+data[key].id}><h5 class="card-title"> {data[key].title}</h5></Link>
        <p class="card-text">{data[key].body}</p>
      </div>
    </div>
  </div>)
})  



        return (
            <div>
                <Link  to="/push/notification/add" class="btn btn-secondary">Add Push Notification</Link>
 <div class="row row-cols-1 row-cols-md-3 g-4 pt-5">
  {dataRender}

</div>    
            </div>
        )
    }
}

export default PushNotification
