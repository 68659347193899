import axios from 'axios'
import React, { Component } from 'react'

export class SinglePushNotificationPage extends Component {
    state = {
        modalopen:false,
        userselect:[],
        pushnotiid:"",
        subscriber:"",
        selectAll:false,
        alluserselectedid : []
    }

    componentDidMount(){
      this.setState({pushnotiid:this.props.match.params.id})
    }
    closeModal = () =>{
        this.setState({
          modalopen:false,
       
        })
      }
      checkboxselectUser=(e)=>{
          console.log(e.target.value)
       if(e.target.checked){
           this.setState({userselect:[...this.state.userselect, e.target.value]})
        
       }else{
        var olduserSelect = this.state.userselect ;
        const index = olduserSelect.indexOf(e.target.value);
        if (index > -1) {
            olduserSelect.splice(index, 1);
            this.setState({userselect:olduserSelect});
        }
       }
     
      }

      SelectAllHandle = (e) =>{
         var newArray = [];
  var subscriberdata = this.state.subscriber;
          var subscriberDataRender = Object.keys(subscriberdata)
          .map(key=>{
         var id =  subscriberdata[key].id;
         if(  e.target.checked){
           newArray.push(id);
           document.getElementById(id).checked = true;
          }else{
           document.getElementById(id).checked = false;
          }
           })
       this.setState({userselect:newArray})
        console.log(newArray);
      }

      SendPushNotificationToAllSelectedUser = () =>{
        axios.post("/push/notification/selecteduser", {
          "pushnotiid": this.state.pushnotiid,"selectedsuser": this.state.userselect
      })
        .then(  (response) =>{
       console.log(response) 
        })
      }


      loadAllSubscriberUser = ()=>{
        this.setState({modalopen:true})
        axios.get("/push/notification/subscriber")
        .then((response)=>{
          console.log(response)
this.setState({subscriber:response.data})
        })
      }
        
      

    render() {

      var subscriberdata = this.state.subscriber;
      var subscriberDataRender = Object.keys(subscriberdata)
      .map(key=>{
      var name =   subscriberdata[key].name
       var id =  subscriberdata[key].id;
      //  this.setState({alluserselectedid: [...this.state.alluserselectedid,id ]})
       return(<div class="form-check">
       <input class="form-check-input" onChange={this.checkboxselectUser} type="checkbox" value={id} id={id}  />
       <label class="form-check-label" for="flexCheckDefault">
        {name}
       </label>
     </div>)
      })
        return (
            <div>
        
 
 
<button type="button" class="btn btn-primary" onClick={this.loadAllSubscriberUser}>
  open
</button>

   {this.state.modalopen?<div class="modal fade show" id="exampleModalLive"  tabindex="-1"  z-indez="99" aria-labelledby="exampleModalLiveLabel" style={{"display": "block"}} aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLiveLabel">Modal title</h5>
        <button onClick={this.closeModal} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div class="form-check">
      <input class="form-check-input" onChange={this.SelectAllHandle} type="checkbox" />
       <label class="form-check-label" for="flexCheckDefault">Select All</label>
       </div>
{subscriberDataRender}



      </div>
      <div class="modal-footer">
        <button onClick={this.closeModal} type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button  onClick={this.SendPushNotificationToAllSelectedUser} type="button" class="btn btn-danger">Send</button>
      </div>
    </div>
  </div>
</div>:""}
{this.state.modalopen?<div class="modal-backdrop fade show"></div>:""}
            </div>
        )
    }
}

export default SinglePushNotificationPage
