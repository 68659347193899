import React, { Component } from 'react'
import Content from './Content'
import Footer from './Footer/Footer'
import Header from './Header/Header'
import SideBar from './SideBar/SideBar'
import $ from 'jquery'

export class Layout extends Component {
    state = {
        profilemenubar:false,
        messagemenuhandle:false,
        notificationmenuhandle:false,
        sidebartoggle:true    }

    profileMenuBar = () =>{
    this.state.profilemenubar?this.setState({profilemenubar:false}):this.setState({profilemenubar:true})
    this.setState({messagemenuhandle:false, notificationmenuhandle:false})
          }
    messageMenuHandler = (e) =>{
    this.state.messagemenuhandle?this.setState({messagemenuhandle:false}):this.setState({messagemenuhandle:true})
    this.setState({notificationmenuhandle:false, profilemenubar:false})
          }
    notificationMenuHandler = (e) =>{
    this.state.notificationmenuhandle?this.setState({notificationmenuhandle:false}):this.setState({notificationmenuhandle:true});
    this.setState({messagemenuhandle:false, profilemenubar:false})
          }

 
    handlerSideBarMenuToggle = () =>{
    this.state.sidebartoggle?this.setState({sidebartoggle:false}):this.setState({sidebartoggle:true});
    
        this.setState({notificationmenuhandle:false,messagemenuhandle:false, profilemenubar:false})
      
 }
 
 componentDidMount(){
    if($(window).width() < 768){
        this.setState({sidebartoggle:false})
    }else if($(window).width() < 480){
        this.setState({sidebartoggle:false})
    }
 }

    render() {
     window.addEventListener('resize', ()=>{
        if($(window).width() < 768){
            this.setState({sidebartoggle:false})
        }else if($(window).width() < 480){
            this.setState({sidebartoggle:false})
        }
         });

       
        return (
            <div>
                  <div id="wrapper">
             
                <SideBar handlemenubar = {this.handlerSideBarMenuToggle}  sidebartoggle= {this.state.sidebartoggle} 
               />
                <div id="content-wrapper" class="d-flex flex-column">
 
<div id="content">
<Header handlerSideBarMenuToggle = {this.handlerSideBarMenuToggle} sidebartoggle= {this.state.sidebartoggle}  profileMenuBar={this.profileMenuBar} profilemenu = {this.state.profilemenubar} messageMenuHandler={this.messageMenuHandler} messagemenu = {this.state.messagemenuhandle} notificationMenuHandler={this.notificationMenuHandler} notificationmenu = {this.state.notificationmenuhandle}/>
<div class="container-fluid">
{/* <Content/> */}

{this.props.children}
    {/* Content here */}
</div>




</div>
<Footer/>
            </div>
                </div>
            </div>
        )
    }
}

export default Layout
